import React, { useState, useEffect } from 'react';
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';
import { signUp } from '../api'; // Import the signUp function
import './SignIn.css';

const SignUp = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false); // State for toggling password visibility
  const [error, setError] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setShowPopup(true);
  }, []);

  const handleSignUp = async (event) => {
    event.preventDefault();
    try {
      const formData = {
        uname: username,
        email,
        password,
      };
      const response = await signUp(formData);
      console.log('Signed up successfully:', response);
      navigate('/signin');
    } catch (err) {
      console.error('Sign-up failed:', err);
      if (err.response && err.response.data && err.response.data.m) {
        setError(err.response.data.m);
      } else {
        setError(err.message);
      }
    }
  };

  return (
    <Container
      maxWidth="xs"
      className="signin-container"
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100vh"
      >
        <Typography component="h1" variant="h5">
          Sign Up
        </Typography>
        {error && <Typography color="error">{error}</Typography>}
        <form onSubmit={handleSignUp} style={{ marginTop: '1rem', width: '100%' }}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            name="username"
            autoComplete="username"
            autoFocus
            placeholder="Enter Username"
            InputLabelProps={{ shrink: false }}
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            name="email"
            autoComplete="email"
            placeholder="Enter Email"
            InputLabelProps={{ shrink: false }}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            type={showPassword ? 'text' : 'password'} // Toggle input type
            id="password"
            autoComplete="new-password"
            placeholder="Enter Password"
            InputLabelProps={{ shrink: false }}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword((prev) => !prev)}
                    edge="end"
                    aria-label="toggle password visibility"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            type="submit"
            fullWidth
            sx={{
              marginTop: 2,
              backgroundColor: 'goldenrod',
              color: 'white',
              '&:hover': {
                backgroundColor: 'grey',
              },
            }}
          >
            Sign Up
          </Button>
        </form>

        <Box display="flex" alignItems="center" width="100%" mt={2}>
          <Divider style={{ flexGrow: 1 }} />
          <Typography variant="body2" style={{ margin: '0 10px' }}>
            or
          </Typography>
          <Divider style={{ flexGrow: 1 }} />
        </Box>

        <Typography variant="body2" style={{ marginTop: '10px' }}>
          Already have an account?{' '}
          <Link to="/signin" style={{ color: '#1976d2', textDecoration: 'none' }}>
            Sign In
          </Link>
        </Typography>
      </Box>

      <Dialog open={showPopup} onClose={() => setShowPopup(false)}>
        <DialogTitle>Disclaimer</DialogTitle>
        <DialogContent>
          <Typography>
            DevelopIQ does not claim any liability for the results of operation after utilizing data provided herein. DevelopIQ utilizes data from a multitude of sources with varying levels of complexity, and so search results should not be treated as a replacement for due diligence. DevelopIQ is meant to supplement the traditional process of land acquisition, not replace it. By clicking “NEXT”, you are acknowledging that DevelopIQ’s search function is the nature presented above and does not bear any responsibility for improper land selection.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowPopup(false)} color="primary">
            Next
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default SignUp;