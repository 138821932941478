// MapInterface.js
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import CustomMarker from './CustomMarker'; // Import the CustomMarker component
import {
  GOOGLE_MAPS_API_KEY,
  MAP_ID,
  GOOGLE_MAPS_LIBRARIES,
} from './googleMapsConfig'; // Import shared config

const mapContainerStyle = {
  height: '100%',
  width: '100%',
};

// Define GEOJSON_URLS outside the component
const GEOJSON_URLS = [
  '/geojson/water/bellavista_water.geojson',
  '/geojson/water/fayetteville_water.geojson',
  '/geojson/water/rogers_water.geojson',
  '/geojson/water/springdale_water.geojson',
  '/geojson/sewer/fayetteville_sewer.geojson',
  '/geojson/sewer/rogers_sewer.geojson',
  '/geojson/sewer/springdale_sewer.geojson',
];

const MapInterface = ({
  places,
  showUtilities,
  showTopography,
  onMarkerClick,
}) => {
  const [map, setMap] = useState(null);

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    version: 'weekly',
    libraries: GOOGLE_MAPS_LIBRARIES, // Use shared libraries
  });

  const validPlaces = useMemo(
    () =>
      places.filter((place) => {
        const lat = Number(place.Latitude);
        const lng = Number(place.Longitude);
        return !isNaN(lat) && !isNaN(lng) && lat !== 0 && lng !== 0;
      }),
    [places]
  );

  // Adjust map bounds to fit markers
  useEffect(() => {
    if (map && validPlaces.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      validPlaces.forEach((place) => {
        bounds.extend({
          lat: Number(place.Latitude),
          lng: Number(place.Longitude),
        });
      });
      map.fitBounds(bounds);
    }
  }, [map, validPlaces]);

  // Load GeoJSON data with debouncing to prevent excessive requests
  const loadGeoJSON = useCallback(() => {
    if (showUtilities && map) {
      // Clear existing data
      map.data.forEach((feature) => {
        map.data.remove(feature);
      });

      // Debounced function to load GeoJSON
      const load = async () => {
        for (const url of GEOJSON_URLS) {
          try {
            await map.data.loadGeoJson(url, null, (features) => {
              features.forEach((feature) => {
                const type = url.includes('water') ? 'water' : 'sewer';
                feature.setProperty('type', type);
              });
            });
          } catch (error) {
            console.error(`Error loading GeoJSON from ${url}:`, error);
          }
        }

        // Style the features
        map.data.setStyle((feature) => {
          const type = feature.getProperty('type');
          if (type === 'water') {
            return {
              strokeColor: 'blue',
              strokeWeight: 2,
            };
          } else if (type === 'sewer') {
            return {
              strokeColor: 'green',
              strokeWeight: 2,
            };
          }
          return {};
        });
      };

      // Debounce loading GeoJSON by 300ms
      const debounceLoad = debounce(load, 300);
      debounceLoad();
    } else if (map) {
      // Clear data layers when utilities are toggled off
      map.data.forEach((feature) => {
        map.data.remove(feature);
      });
    }
  }, [showUtilities, map]);

  useEffect(() => {
    loadGeoJSON();
  }, [loadGeoJSON]);

  const mapOptions = useMemo(
    () => ({
      // Include mapId if using
      // mapId: MAP_ID,
      mapTypeId: showTopography ? 'terrain' : 'roadmap',
      streetViewControl: false,
      mapTypeControl: false,
      maxZoom: 19,
    }),
    [showTopography]
  );

  const onLoad = useCallback((mapInstance) => {
    setMap(mapInstance);
  }, []);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading Maps...</div>;
  }

  return (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      onLoad={onLoad}
      onUnmount={onUnmount}
      options={mapOptions}
    >
      {/* Markers */}
      {validPlaces.map((place) => (
        <CustomMarker
          key={place.h} // Ensure 'h' is unique for each place
          map={map}
          position={{
            lat: Number(place.Latitude),
            lng: Number(place.Longitude),
          }}
          onClick={() => onMarkerClick(place)}
        />
      ))}
      {/* GeoJSON Layers are handled via map.data in the useEffect above */}
    </GoogleMap>
  );
};

export default MapInterface;

// Debounce function
function debounce(func, wait) {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}
