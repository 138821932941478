// ResultPopup.js

import React, { useState, useEffect, useCallback, useMemo } from 'react';
import './ResultPopup.css';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { Switch } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import CustomMarker from './CustomMarker'; // Import the CustomMarker component
import axios from 'axios';
import {
  GOOGLE_MAPS_API_KEY,
  MAP_ID,
  GOOGLE_MAPS_LIBRARIES,
} from './googleMapsConfig'; // Import shared config

const mapContainerStyle = {
  height: '100%',
  width: '100%',
};

// Define GEOJSON_URLS outside the component
const GEOJSON_URLS = [
  '/geojson/water/bellavista_water.geojson',
  '/geojson/water/fayetteville_water.geojson',
  '/geojson/water/rogers_water.geojson',
  '/geojson/water/springdale_water.geojson',
  '/geojson/sewer/fayetteville_sewer.geojson',
  '/geojson/sewer/rogers_sewer.geojson',
  '/geojson/sewer/springdale_sewer.geojson',
];

const ResultPopup = ({ place, onClose, userStatus, token }) => {
  const [showUtilities, setShowUtilities] = useState(false);
  const [showTopography, setShowTopography] = useState(false);
  const [map, setMap] = useState(null);

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    version: 'weekly',
    libraries: GOOGLE_MAPS_LIBRARIES, // Use shared libraries
  });

  const onLoad = useCallback((mapInstance) => {
    setMap(mapInstance);
  }, []);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  // Center the map on the selected place
  useEffect(() => {
    if (map && place) {
      const lat = Number(place.Latitude);
      const lng = Number(place.Longitude);
      if (!isNaN(lat) && !isNaN(lng)) {
        map.panTo({ lat, lng });
        map.setZoom(15);
      }
    }
  }, [map, place]);

  // Load GeoJSON data when utilities switch is toggled
  const loadGeoJSON = useCallback(() => {
    if (map) {
      // Clear existing data
      map.data.forEach((feature) => {
        map.data.remove(feature);
      });

      if (showUtilities) {
        // Load GeoJSON data
        GEOJSON_URLS.forEach((url) => {
          map.data.loadGeoJson(url, null, (features) => {
            features.forEach((feature) => {
              const type = url.includes('water') ? 'water' : 'sewer';
              feature.setProperty('type', type);
            });
          });
        });

        // Style the features
        map.data.setStyle((feature) => {
          const type = feature.getProperty('type');
          if (type === 'water') {
            return {
              strokeColor: 'blue',
              strokeWeight: 2,
            };
          } else if (type === 'sewer') {
            return {
              strokeColor: 'green',
              strokeWeight: 2,
            };
          }
          return {};
        });
      }
    }
  }, [showUtilities, map]);

  useEffect(() => {
    loadGeoJSON();
  }, [loadGeoJSON]);

  const mapOptions = useMemo(
    () => ({
      // Include mapId if using
      // mapId: MAP_ID,
      mapTypeId: showTopography ? 'terrain' : 'roadmap',
      streetViewControl: false,
      mapTypeControl: false,
    }),
    [showTopography]
  );

  const handleUtilitiesToggle = (event) => {
    setShowUtilities(event.target.checked);
  };

  const handleTopographyToggle = (event) => {
    setShowTopography(event.target.checked);
  };

  const handlePaymentClick = async () => {
    try {
      const response = await axios.post('https://api.developiq.org/api', {
        m: 'getPaymentURL',
        tok: token,
      });
      const { url } = response.data;
      window.location.href = url;
    } catch (error) {
      console.error('Error creating payment session:', error);
      alert(
        'An error occurred while processing your payment. Please try again.'
      );
    }
  };

  if (!place) return null;

  const primaryPhoto = place.Primary_Photo || '';
  const altPhotos = place.Alt_Photos ? place.Alt_Photos.split(', ') : [];
  const fallbackImage = '/path-to-default-image.jpg'; // Ensure this path is correct

  const renderRestrictedItem = (title) => (
    <div className="info-item-popup restricted-info-item-popup">
      <LockIcon className="lock-icon" />
      <strong className="info-title">{title}</strong>
    </div>
  );

  // Helper function to format numbers to two decimal places
  const formatNumber = (num) => {
    const parsedNum = parseFloat(num);
    if (isNaN(parsedNum))
      return 'Currently Unavailable - Refer to your local municipality';
    return parsedNum.toFixed(2);
  };

  // Determine if the user is paid
  const isPaidUser = userStatus === 'PAID' || userStatus === 'ADMIN';

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading Maps...</div>;
  }

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <button className="close-button" onClick={onClose}>
          X
        </button>
        <div className="popup-top">
          <div className="popup-image-section">
            <img
              src={primaryPhoto || altPhotos[0] || fallbackImage}
              alt={place.Full_Address || 'Unknown Address'}
              className="popup-image"
              onError={(e) => {
                e.target.src = fallbackImage;
              }}
            />
          </div>
          <div className="popup-map-section">
            <GoogleMap
              mapContainerStyle={mapContainerStyle}
              onLoad={onLoad}
              onUnmount={onUnmount}
              options={mapOptions}
            >
              {/* Marker */}
              {place && (
                <CustomMarker
                  map={map}
                  position={{
                    lat: Number(place.Latitude),
                    lng: Number(place.Longitude),
                  }}
                  onClick={() => {
                    /* Handle marker click if needed */
                  }}
                />
              )}
              {/* GeoJSON Layers are handled via map.data in the useEffect above */}
            </GoogleMap>
            {/* Utility and Topography Toggles */}
            <div className="toggle-container">
              <div className="toggle-item">
                <span>Utilities</span>
                <Switch
                  checked={showUtilities}
                  onChange={handleUtilitiesToggle}
                />
              </div>
              <div className="toggle-item">
                <span>Topography</span>
                <Switch
                  checked={showTopography}
                  onChange={handleTopographyToggle}
                />
              </div>
            </div>
            {/* Legend - only show when utilities switch is on */}
            {showUtilities && (
              <div className="legend">
                <p>
                  <span className="legend-color legend-water"></span>Water
                </p>
                <p>
                  <span className="legend-color legend-sewer"></span>Sewage
                </p>
              </div>
            )}
          </div>
        </div>
        <h2 className="popup-price">
          $
          {place.List_Price !== undefined
            ? parseFloat(place.List_Price).toLocaleString(undefined, {
                minimumFractionDigits: 2,
              })
            : 'Currently Unavailable - Refer to your local municipality'}
        </h2>
        <h3 className="popup-name">
          {place.Full_Address || 'Unknown Address'}
        </h3>
        <div className="popup-info">
          <p>
            Acreage:{' '}
            {place.Lot_Acreage
              ? formatNumber(place.Lot_Acreage)
              : 'Currently Unavailable - Refer to your local municipality'}
          </p>
          <p>
            Market Status:{' '}
            {place.Status ||
              'Currently Unavailable - Refer to your local municipality'}
          </p>
        </div>
        {isPaidUser ? (
          <div className="popup-details">
            {/* Zoning and Utilities Side by Side */}
            <div className="info-sections-row">
              {/* Zoning Section */}
              <div className="info-section">
                <h4>Zoning</h4>
                <div className="info-item-popup">
                  <strong>Zoning Regulations</strong>
                  <p>
                    {place.Zone ||
                      'Currently Unavailable - Refer to your local municipality'}
                  </p>
                </div>
              </div>
              {/* Utilities Section */}
              <div className="info-section">
                <h4>Utilities</h4>
                <div className="info-item-popup">
                  <strong>Sewage Access Distance</strong>
                  <p>
                    {place.Sewage_Dist_Meters
                      ? `${formatNumber(place.Sewage_Dist_Meters)} meters`
                      : 'Currently Unavailable - Refer to your local municipality'}
                  </p>
                </div>
                <div className="info-item-popup">
                  <strong>Water Access Distance</strong>
                  <p>
                    {place.Dist_to_Water
                      ? `${formatNumber(place.Dist_to_Water)} meters`
                      : 'Currently Unavailable - Refer to your local municipality'}
                  </p>
                </div>
              </div>
            </div>
            {/* Other Sections Below */}
            {/* Community Insights Section */}
            <div className="info-section full-width">
              <h4>Community Insights</h4>
              <div className="info-item-popup">
                <strong>Closest Hospital</strong>
                <p>
                  {place.Closest_Hospital ||
                    'Currently Unavailable - Refer to your local municipality'}
                </p>
              </div>
              <div className="info-item-popup">
                <strong>Closest School</strong>
                <p>
                  {place.Nearby_Schools ||
                    'Currently Unavailable - Refer to your local municipality'}
                </p>
              </div>
              <div className="info-item-popup">
                <strong>Closest Religious Building Distance</strong>
                <p>
                  {place.Dist_to_Church
                    ? `${formatNumber(place.Dist_to_Church)} km`
                    : 'Currently Unavailable - Refer to your local municipality'}
                </p>
              </div>
              <div className="info-item-popup">
                <strong>Closest Park</strong>
                <p>
                  {place.Closest_Park ||
                    'Currently Unavailable - Refer to your local municipality'}
                </p>
              </div>
            </div>
            {/* Price Comparison Section */}
            <div className="info-section full-width">
              <h4>Price Comparison</h4>
              <div className="info-item-popup">
                <strong>Median Price Per Acre Sold</strong>
                <p>
                  {place.Median_Price_Per_Acre_Sold
                    ? `${parseFloat(
                        formatNumber(place.Median_Price_Per_Acre_Sold)
                      ).toLocaleString()} per acre`
                    : 'Currently Unavailable - Refer to your local municipality'}
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className="popup-details">
            <p
              className="pay-to-unlock-message"
              onClick={handlePaymentClick}
              style={{
                cursor: 'pointer',
                color: 'blue',
                textDecoration: 'underline',
              }}
            >
              Pay here for this information
            </p>
            {/* Zoning and Utilities Side by Side */}
            <div className="info-sections-row">
              {/* Zoning Section */}
              <div className="info-section">
                <h4>Zoning</h4>
                {renderRestrictedItem('Zoning Regulations')}
              </div>
              {/* Utilities Section */}
              <div className="info-section">
                <h4>Utilities</h4>
                {renderRestrictedItem('Sewage Access')}
                {renderRestrictedItem('Water Access')}
              </div>
            </div>
            {/* Other Sections Below */}
            {/* Community Insights Section */}
            <div className="info-section full-width">
              <h4>Community Insights</h4>
              {renderRestrictedItem('Closest Hospital')}
              {renderRestrictedItem('Closest School')}
              {renderRestrictedItem('Closest Religious Building')}
              {renderRestrictedItem('Closest Park')}
            </div>
            {/* Price Comparison Section */}
            <div className="info-section full-width">
              <h4>Price Comparison</h4>
              {renderRestrictedItem('Median Price Per Acre Sold')}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ResultPopup;
