// CustomMarker.js

import React, { useEffect } from 'react';
import markerIcon from './marker-icon.png'; // Ensure the path is correct

const CustomMarker = React.memo(({ position, onClick, map }) => {
  useEffect(() => {
    if (map && window.google) {
      const marker = new window.google.maps.Marker({
        map,
        position,
        icon: {
          url: markerIcon, // Ensure markerIcon is correctly imported
          scaledSize: new window.google.maps.Size(32, 32), // Adjust size as needed
        },
      });

      marker.addListener('click', onClick);

      return () => {
        marker.setMap(null);
      };
    }
  }, [map, position, onClick]);

  return null;
});

export default CustomMarker;
